blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 20px !important;
}
.article-section > blockquote {
  padding: 0.5em 20px !important;
}
.article-section p {
  text-transform: none;
  font-family: BreveText, helvetica, sans-serif;
  font-feature-settings: normal;
  font-style: normal;
  letter-spacing: 0.00568421em;
  line-break: auto;
  line-height: 1.47368em;
  font-size: 19px;
  font-weight: 400;
  overflow-wrap: normal;
  color: rgb(26, 26, 26);
}
.article-section ul {
  text-transform: none;
  font-family: BreveText, helvetica, sans-serif;
  font-feature-settings: normal;
  font-style: normal;
  letter-spacing: 0.00568421em;
  line-break: auto;
  line-height: 1.47368em;
  font-size: 19px;
  font-weight: 400;
  overflow-wrap: normal;
  color: rgb(26, 26, 26);
}
blockquote p {
  display: inline;
}
.article-img img {
  margin: auto;
  /* max-width: 100%; */
  max-height: 900px !important;
}
.article-img {
  text-align: center !important;
}
.article-section > p > img {
  margin: auto !important;
  display: block !important;
}
.article-section * {
  max-width: 100%;
}
