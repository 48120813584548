.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #de1736;
}

@media only screen and (max-width: 600px) {
  .mobile-main-menup-padding {
    padding: 0px !important;
  }
}

.ck-content .text-tiny {
  font-size: 0.7em;
}

.ck-content .text-small {
  font-size: 0.85em;
}

.ck-content .text-big {
  font-size: 1.4em;
}

.ck-content .text-huge {
  font-size: 1.8em;
}

:root {
  --ck-image-style-spacing: 1.5em;
}

.ck-content .image-style-side,
.ck-content .image-style-align-left,
.ck-content .image-style-align-center,
.ck-content .image-style-align-right {
  max-width: 100%;
}
.ck-content .image-style-side {
  margin-left: var(--ck-image-style-spacing);
}
.ck-content .image-style-align-left {
  text-align: left;
  margin-right: var(--ck-image-style-spacing);
}
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.ck-content .image-style-align-right {
  text-align: right;

  margin-left: var(--ck-image-style-spacing);
}

.ck-content .image {
  margin: auto;

}

.ck-content .image img {
  width: 100%;
}

/* .ck-content .image.image_resized {
  display: block;
  box-sizing: border-box;
} */

.ck-content .image.image_resized > figcaption {
  display: block;
}

.ck-content figure.table,
.ck-content figure.table table {
  /* width: 100% !important; */
  overflow: auto !important;
}

@media screen and (max-width: 480px) {
  .ck-content .image.image_resized {
    width: 100% !important;
    max-width: 100% !important;
  }
}
