.MuiLinearProgress-barColorPrimary {
  background-color: #002e5d !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #dcdcdc !important;
}

.MuiLinearProgress-root {
  height: 1.25rem !important;
  border-radius: 3px;
}

.poll-percentage {
  /* position: absolute; */
  color: #fff;
  /* top: 31px; */
  /* left: 5%; */
  padding-left: 5px;
  font-weight: 500;
}
